<template>
    <div class="container_warp">
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/iotConfigManage' }">组态管理</el-breadcrumb-item>
          <el-breadcrumb-item>组态详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="top-operating">
        {{cellData.name}}       
      </div>
      <div id="containerDetailChart"></div>
      <!-- <div style="border:1px solid red;width:100%;height:100%;">
        <svg width="100%" height="100%" xmlns:xlink="http://www.w3.org/1999/xlink">
          <defs></defs>
          <g>
            <g v-for="(item, index) in cellData.cells" :key="index" :data-cell-id="item.id" :data-shape="item.shape" :transform="`translate(${item.position.x},${item.position.y})`">
              <g v-show="item.shape=='rect'">
                <rect fill="#ffffff" stroke="#000000" stroke-width="1" rx="10" ry="10" width="120" height="40"></rect>
                <text font-size="14" fill="#000000" text="按钮" text-anchor="middle" transform="matrix(1, 0, 0, 1, 60, 25)">
                  <tspan>按钮</tspan>
                </text>
              </g>
              <g v-show="item.shape=='circle'">
                <circle fill="#ffffff" stroke="#000000" stroke-width="1" cx="35" cy="35" r="35"></circle>
              </g>
              <g v-show="item.shape=='image'">

                <rect fill="#ffffff" stroke="#000000" stroke-width="1" rx="10" ry="10" width="120" height="40"></rect>
              </g>
            </g>
          </g>
        </svg>
      </div> -->
    </div>
</template>
<script>
import '@antv/x6-vue-shape'
import { Graph,Shape,FunctionExt,DataUri} from '@antv/x6';
import insertCss from 'insert-css';

const data = {};
export default {
  data() {
    return {
      row: null,
      cellData: {},
      graph:'',
      type:'grid',
      selectCell:'',
      connectEdgeType:{  //连线方式
        connector: 'normal',
        router: {
          name: ''
        }
      },
      grid:{ // 网格设置
        size: 12,      // 网格大小 10px
        visible: false, // 渲染网格背景
        type: 'dot',
        args: {
          color: '#D0D0D0',
          thickness: 1,     // 网格线宽度/网格点大小
          factor: 10,
        },
      },
      wsdata: {},
      isButtonPushData: null,
    }
  },
  methods: {
    initX6(){
        var _that = this
        this.graph = new Graph({
            container: document.getElementById('containerDetailChart'),
            width: '100%',
            height: 750,
            grid: _that.grid,
            panning: {
              enabled: false,//开启平移
              modifiers: 'ctrl',//如何其他操作有冲突,可用修饰参数触发
              eventTypes: ['leftMouseDown','rightMouseDown','mouseWheel'],//设置触发画布的拖拽行为,默认为['leftMouseDown']          
            },
            mousewheel: {
              enabled: false,//开启滚轮缩放
              modifiers: ['ctrl','meta'],
            },
            resizing: { //调整节点宽高
              enabled: false,
              orthogonal:false,
            }, 
            translating: {//限制节点移动范围(不能超出画布)
              restrict: true,
            },
            snapline:  false,//开启对齐线
            interacting: {//节点拖动
              nodeMovable: false,//禁止节点拖动
              edgeLabelMovable: false,//禁止边拖动
            },
            selecting: {
              enabled: false,//开启点选框选
              multiple: true,
              rubberband: true,
              strict: true,
            },
            connecting: { // 节点连接
              anchor: 'center',
              connectionPoint: 'anchor',
              allowBlank: false,
              snap: true,
              createEdge () {
                return new Shape.Edge({
                  attrs: {
                    line: {
                      stroke: '#1890ff',
                      strokeWidth: 1,
                      targetMarker: {
                        name: 'classic',
                        size: 8
                      },
                      strokeDasharray: 0, //虚线
                      style: {
                        animation: 'ant-line 30s infinite linear',
                      },
                    },
                  },
                  label: {
                    text:''
                  },
                  connector: _that.connectEdgeType.connector,
                  router: {
                    name: _that.connectEdgeType.router.name || ''
                  },
                  zIndex: 0
                })
              },
            },
            highlighting: {
              magnetAvailable: {
                name: 'stroke',
                args: {
                  padding: 4,
                  attrs: {
                    strokeWidth: 4,
                    stroke: '#6a6c8a'
                  }
                }
              }
            },
        });
        insertCss(`
          @keyframes ant-line {
            to {
                stroke-dashoffset: -1000
            }
          }
        `)
        this.graph.fromJSON(data)
        this.graph.history.redo()
        this.graph.history.undo()
        // 鼠标移入移出节点
        this.graph.on('node:mouseenter',FunctionExt.debounce(() => {
            const container =  document.getElementById('containerDetailChart')
            const ports = container.querySelectorAll(
              '.x6-port-body'
            )
            this.showPorts(ports, false)
          }),
          500
        )
        this.graph.on('node:mouseleave', () => {
          const container =  document.getElementById('containerDetailChart')
          const ports = container.querySelectorAll(
            '.x6-port-body'
          )
          this.showPorts(ports, false)
        })

        // 监听cell点击事件
        this.graph.on('cell:click', ({ cell }) => {
          this.type = cell.isNode() ? 'node' : 'edge'
          let cellData = cell.store.data.data
          if(cellData&&cellData.eid==this.wsdata.subEid) {
            // 如果是开关
            if(cellData&&cellData.graphical.action==1) {
              // if(cellData.graphical.status==0) {
              //   cellData.graphical.status = 1
              //   cell.attr('body/fill', '#fff')
              //   cell.attr('body/stroke', '#fff')
              //   cell.attr('label/text', '关')
              //   cell.attr('label/fill', '#000000')
              // }else {
              //   cellData.graphical.status = 0
              //   cell.attr('body/fill', '#0abde3')
              //   cell.attr('body/stroke', '#0abde3')
              //   cell.attr('label/text', '开')
              //   cell.attr('label/fill', '#ffffff')
              // }
              console.log(cell)
              let gatewayEid = nodeData.gatewayEid
              let eid = nodeData.eid
              let tag = nodeData.graphical.pushTag
              let tagValue = nodeData.graphical.pushTagValue
              this.buttonPushData(gatewayEid,eid,tag,tagValue)
              // this.handleDeviceNode()
            }
          }
        })

        // 监听node鼠标按下事件
        this.graph.on('node:mousedown', ({ node }) => {
          let nodeData = node.store.data.data
          if(nodeData&&nodeData.graphicalType=='button'&&nodeData.graphical.action==0) {
            console.log('button鼠标按下')
            node.attr('body/fill', '#0abde3')
            node.attr('body/strokeWidth', 0)
            node.attr('label/fill', '#ffffff')

            let gatewayEid = nodeData.gatewayEid
            let eid = nodeData.eid
            let tag = nodeData.graphical.downTag
            let tagValue = nodeData.graphical.downTagValue
            this.buttonPushData(gatewayEid,eid,tag,tagValue)
          }
        })

        // 监听node鼠标松开事件
        this.graph.on('node:mouseup', ({ node }) => {
          let nodeData = node.store.data.data
          if(nodeData&&nodeData.graphicalType=='button'&&nodeData.graphical.action==0) {
            console.log(node)
            console.log('button鼠标抬起')
            node.attr('body/fill', '#ffffff')
            node.attr('body/strokeWidth', 0)
            node.attr('label/fill', '#000000')

            let gatewayEid = nodeData.gatewayEid
            let eid = nodeData.eid
            let tag = nodeData.graphical.upTag
            let tagValue = nodeData.graphical.upTagValue
            if(this.isButtonPushData == 1) {
              this.buttonPushData(gatewayEid,eid,tag,tagValue)
            }
          }
        })

        this.graph.on('selection:changed', (args) => {
          args.added.forEach(cell => {
            this.selectCell = cell
            if(cell.isEdge()){
              cell.isEdge() && cell.attr('line/strokeDasharray', 5) //虚线蚂蚁线
              cell.addTools([
                {
                  name: 'vertices',
                  args: {
                    padding: 4,
                    attrs: {
                      strokeWidth: 0.1,
                      stroke: '#2d8cf0',
                      fill: '#ffffff',
                    }
                  },
                },
              ])
            }
          })
          args.removed.forEach(cell => {
            cell.isEdge() && cell.attr('line/strokeDasharray', 0)  //正常线
            cell.removeTools()
          })
        })
    },
    showPorts (ports, show) {
      for (let i = 0, len = ports.length; i < len; i = i + 1) {
        ports[i].style.visibility = show ? 'visible' : 'hidden'
      }
    },
    
    // 水流动画
    waterLinear(v) {
      v.attr({
        image: {
          style: {
            animation: 'animations 3s linear infinite normal',
          }
        },
        image1: {
          style: {
            animation: 'animations 3s linear infinite normal',
          }
        }
      })
      insertCss(`
        @keyframes animations {
          to {
            transform: translateX(${v.size().width}px);
          }
        }
      `)
      return v
    },


    // 处理接口数据和画布节点数据为相同设备的节点
    handleDeviceNode() {
      // 获取所有画布上节点
      let allNodes = this.graph.getNodes()
      //循环画布上所有节点
      allNodes.forEach(v=>{
        //判断是否是同一设备
        if(this.wsdata.subEid==v.store.data.data.eid) {
          //循环websocket推送Tag数据
          this.wsdata.content.contents.forEach(item=>{
            //判断节点类型为image
            if(v.store.data.shape=='image') {
              //循环节点类型为image的Tag数据
              for(let tag in v.store.data.data.graphical.tagAnimation) {
                //判断tag是否是同一个
                if(tag == item.id) {
                  // console.log(v)
                  for(let tagVal in v.store.data.data.graphical.tagAnimation[tag]) {
                    if(v.store.data.data.graphical.tagAnimation[tag][tagVal]=='PipelineFlow') {//流水动画
                      v.attr('svgele/class', 'showNode')
                      this.waterLinear(v)
                    }else {//无动画
                      v.attr('svgele/class', 'hideNode')
                    }
                  }
                }
              }
            }
          })
        }
      })
    },

    // 组态详情接口
    loadDetailData() {
      this.$ajax.post('configurationDetail', {}, { configurationId: this.row }).then(res => {
        // 组态结构全局赋值
        this.cellData = res.data

        // 将接口数据渲染至画布上
        this.graph.fromJSON(this.cellData.cells)

        // 隐藏链接桩port
        const container =  document.getElementById('containerDetailChart')
        const ports = container.querySelectorAll('.x6-port-body')
        this.showPorts(ports, false)
        this.handleDeviceNode()
      })
    },

    // 点击按钮下发数据
    buttonPushData(gatewayEid,eid,tag,tagValue) {
      this.$ajax.post("configButtonPushData", {
        gatewayEid: gatewayEid,
        eid: eid,
        tag: tag,
        tagValue: tagValue,
        projectId: sessionStorage.getItem('configProjectId'),
        configurationId: sessionStorage.getItem('configurationId'),
      }).then((res) => {
        if(res.code==0) {
          this.$message.success("下发成功");
          this.isButtonPushData = 1
        }else {
          this.$message.error('下发失败');
          this.isButtonPushData = null
        }
      })
    }
  },
  
  mounted(){
    this.initX6()
    if (this.$route.params.row == null) {
      this.$router.push('/iotConfigManage')
    } else {
      this.row = this.$route.params.row
      this.wsdata = {
        "companyId": 4,
        "eid": "TS240305006012235",
        "subEid": "S7-400-01",
        "deviceTypeCode": "GatewaySub",
        "supplierCode": "SIEMENS",
        "equipmentBrandCode": "S7-400",
        "equipmentName": "西门子plc",
        "subEquipmentName": "西门子plc",
        "content": {
            "type": "real",
            "sn": "TS240305006012235",
            "contents": [
                {
                    "id": "_io_status",
                    "desc": "????????????",
                    "quality": "0",
                    "value": "1.000"
                },
                {
                    "id": "Tag1",
                    "desc": "????????????",
                    "quality": "0",
                    "value": "1.000"
                },
                {
                    "id": "Tag2",
                    "desc": "1#?????????????????????",
                    "quality": "0",
                    "value": "1.000"
                },
                {
                    "id": "Tag3",
                    "desc": "1#??????????????????",
                    "quality": "0",
                    "value": "1.000"
                },
                {
                    "id": "Tag4",
                    "desc": "1#??????????????????",
                    "quality": "0",
                    "value": "0.000"
                },
                {
                    "id": "Tag5",
                    "desc": "???",
                    "quality": "1",
                    "value": "0.000"
                },
                {
                    "id": "Tag6",
                    "desc": "1#???????????????",
                    "quality": "0",
                    "value": "93.555"
                },
                {
                    "id": "Tag7",
                    "desc": "1#??????????????????",
                    "quality": "0",
                    "value": "39.010"
                },
                {
                    "id": "Tag8",
                    "desc": "1#??????????????????",
                    "quality": "0",
                    "value": "9.810"
                },
                {
                    "id": "Tag9",
                    "desc": "1#???????????????",
                    "quality": "0",
                    "value": "39.020"
                },
                {
                    "id": "Tag10",
                    "desc": "1#???????????????",
                    "quality": "0",
                    "value": "9.790"
                },
                {
                    "id": "Tag11",
                    "desc": "1#???????????????or??????",
                    "quality": "0",
                    "value": "1.000"
                },
                {
                    "id": "Tag12",
                    "desc": "1#?????????????????????",
                    "quality": "0",
                    "value": "1.000"
                },
                {
                    "id": "Tag13",
                    "desc": "1#?????????????????????",
                    "quality": "0",
                    "value": "0.000"
                },
                {
                    "id": "Tag14",
                    "desc": "1#???????????????????????????",
                    "quality": "0",
                    "value": "0.000"
                },
                {
                    "id": "Tag15",
                    "desc": "1#???????????????????????????",
                    "quality": "0",
                    "value": "0.000"
                },
                {
                    "id": "Tag16",
                    "desc": "1#????????????????????????",
                    "quality": "0",
                    "value": "0.000"
                },
                {
                    "id": "Tag17",
                    "desc": "???",
                    "quality": "1",
                    "value": "0.000"
                },
                {
                    "id": "Tag18",
                    "desc": "2#?????????????????????",
                    "quality": "0",
                    "value": "1.000"
                },
                {
                    "id": "Tag19",
                    "desc": "2#??????????????????",
                    "quality": "0",
                    "value": "0.000"
                },
                {
                    "id": "Tag20",
                    "desc": "2#??????????????????",
                    "quality": "0",
                    "value": "0.000"
                },
                {
                    "id": "Tag21",
                    "desc": "???",
                    "quality": "1",
                    "value": "0.000"
                },
                {
                    "id": "Tag22",
                    "desc": "2#???????????????",
                    "quality": "0",
                    "value": "0.817"
                },
                {
                    "id": "Tag23",
                    "desc": "2#??????????????????",
                    "quality": "0",
                    "value": "39.030"
                },
                {
                    "id": "Tag24",
                    "desc": "2#??????????????????",
                    "quality": "0",
                    "value": "9.820"
                },
                {
                    "id": "Tag25",
                    "desc": "2#???????????????",
                    "quality": "0",
                    "value": "39.040"
                },
                {
                    "id": "Tag26",
                    "desc": "2#???????????????",
                    "quality": "0",
                    "value": "9.780"
                },
                {
                    "id": "Tag27",
                    "desc": "2#???????????????or??????",
                    "quality": "0",
                    "value": "1.000"
                },
                {
                    "id": "Tag28",
                    "desc": "2#?????????????????????",
                    "quality": "0",
                    "value": "0.000"
                },
                {
                    "id": "Tag29",
                    "desc": "2#?????????????????????",
                    "quality": "0",
                    "value": "1.000"
                },
                {
                    "id": "Tag30",
                    "desc": "2#???????????????????????????",
                    "quality": "0",
                    "value": "0.000"
                },
                {
                    "id": "Tag31",
                    "desc": "2#???????????????????????????",
                    "quality": "0",
                    "value": "0.000"
                },
                {
                    "id": "Tag32",
                    "desc": "2#????????????????????????",
                    "quality": "0",
                    "value": "0.000"
                },
                {
                    "id": "Tag33",
                    "desc": "???",
                    "quality": "1",
                    "value": "0.000"
                },
                {
                    "id": "Tag34",
                    "desc": "3#?????????????????????",
                    "quality": "0",
                    "value": "1.000"
                },
                {
                    "id": "Tag35",
                    "desc": "3#??????????????????",
                    "quality": "0",
                    "value": "0.000"
                },
                {
                    "id": "Tag36",
                    "desc": "3#??????????????????",
                    "quality": "0",
                    "value": "0.000"
                },
                {
                    "id": "Tag37",
                    "desc": "???",
                    "quality": "1",
                    "value": "0.000"
                },
                {
                    "id": "Tag38",
                    "desc": "3#???????????????",
                    "quality": "0",
                    "value": "-0.101"
                },
                {
                    "id": "Tag39",
                    "desc": "3#??????????????????",
                    "quality": "0",
                    "value": "39.050"
                },
                {
                    "id": "Tag40",
                    "desc": "3#??????????????????",
                    "quality": "0",
                    "value": "9.830"
                },
                {
                    "id": "Tag41",
                    "desc": "3#???????????????",
                    "quality": "0",
                    "value": "39.060"
                },
                {
                    "id": "Tag42",
                    "desc": "3#???????????????",
                    "quality": "0",
                    "value": "9.770"
                },
                {
                    "id": "Tag43",
                    "desc": "3#???????????????or??????",
                    "quality": "0",
                    "value": "1.000"
                },
                {
                    "id": "Tag44",
                    "desc": "3#?????????????????????",
                    "quality": "0",
                    "value": "0.000"
                },
                {
                    "id": "Tag45",
                    "desc": "3#?????????????????????",
                    "quality": "0",
                    "value": "1.000"
                },
                {
                    "id": "Tag46",
                    "desc": "3#???????????????????????????",
                    "quality": "0",
                    "value": "0.000"
                },
                {
                    "id": "Tag47",
                    "desc": "3#???????????????????????????",
                    "quality": "0",
                    "value": "0.000"
                },
                {
                    "id": "Tag48",
                    "desc": "3#????????????????????????",
                    "quality": "0",
                    "value": "0.000"
                },
                {
                    "id": "Tag49",
                    "desc": "???",
                    "quality": "1",
                    "value": "0.000"
                },
                {
                    "id": "Tag50",
                    "desc": "4#?????????????????????",
                    "quality": "0",
                    "value": "1.000"
                },
                {
                    "id": "Tag51",
                    "desc": "4#??????????????????",
                    "quality": "0",
                    "value": "0.000"
                },
                {
                    "id": "Tag52",
                    "desc": "4#??????????????????",
                    "quality": "0",
                    "value": "0.000"
                },
                {
                    "id": "Tag53",
                    "desc": "???",
                    "quality": "1",
                    "value": "0.000"
                },
                {
                    "id": "Tag54",
                    "desc": "4#???????????????",
                    "quality": "0",
                    "value": "0.051"
                },
                {
                    "id": "Tag55",
                    "desc": "4#??????????????????",
                    "quality": "0",
                    "value": "39.070"
                },
                {
                    "id": "Tag56",
                    "desc": "4#??????????????????",
                    "quality": "0",
                    "value": "9.850"
                },
                {
                    "id": "Tag57",
                    "desc": "4#???????????????",
                    "quality": "0",
                    "value": "39.080"
                },
                {
                    "id": "Tag58",
                    "desc": "4#???????????????",
                    "quality": "0",
                    "value": "9.760"
                },
                {
                    "id": "Tag59",
                    "desc": "4#???????????????or??????",
                    "quality": "0",
                    "value": "1.000"
                },
                {
                    "id": "Tag60",
                    "desc": "4#?????????????????????",
                    "quality": "0",
                    "value": "0.000"
                },
                {
                    "id": "Tag61",
                    "desc": "4#?????????????????????",
                    "quality": "0",
                    "value": "1.000"
                },
                {
                    "id": "Tag62",
                    "desc": "4#???????????????????????????",
                    "quality": "0",
                    "value": "0.000"
                },
                {
                    "id": "Tag63",
                    "desc": "4#???????????????????????????",
                    "quality": "0",
                    "value": "0.000"
                },
                {
                    "id": "Tag64",
                    "desc": "4#????????????????????????",
                    "quality": "0",
                    "value": "0.000"
                },
                {
                    "id": "Tag65",
                    "desc": "???",
                    "quality": "1",
                    "value": "0.000"
                },
                {
                    "id": "Tag66",
                    "desc": "5#?????????????????????",
                    "quality": "0",
                    "value": "1.000"
                },
                {
                    "id": "Tag67",
                    "desc": "5#??????????????????",
                    "quality": "0",
                    "value": "0.000"
                },
                {
                    "id": "Tag68",
                    "desc": "5#???????????????",
                    "quality": "0",
                    "value": "0.629"
                },
                {
                    "id": "Tag69",
                    "desc": "5#??????????????????",
                    "quality": "0",
                    "value": "39.120"
                },
                {
                    "id": "Tag70",
                    "desc": "5#??????????????????",
                    "quality": "0",
                    "value": "9.870"
                },
                {
                    "id": "Tag71",
                    "desc": "5#???????????????",
                    "quality": "0",
                    "value": "39.520"
                },
                {
                    "id": "Tag72",
                    "desc": "5#???????????????",
                    "quality": "0",
                    "value": "9.750"
                },
                {
                    "id": "Tag73",
                    "desc": "5#???????????????or??????",
                    "quality": "0",
                    "value": "1.000"
                },
                {
                    "id": "Tag74",
                    "desc": "5#?????????????????????",
                    "quality": "0",
                    "value": "0.000"
                },
                {
                    "id": "Tag75",
                    "desc": "5#?????????????????????",
                    "quality": "0",
                    "value": "1.000"
                },
                {
                    "id": "Tag76",
                    "desc": "5#???????????????????????????",
                    "quality": "0",
                    "value": "0.000"
                },
                {
                    "id": "Tag77",
                    "desc": "5#???????????????????????????",
                    "quality": "0",
                    "value": "0.000"
                },
                {
                    "id": "Tag78",
                    "desc": "5#????????????????????????",
                    "quality": "0",
                    "value": "0.000"
                },
                {
                    "id": "Tag79",
                    "desc": "1#???????????????",
                    "quality": "0",
                    "value": "2.359"
                },
                {
                    "id": "Tag80",
                    "desc": "2#???????????????",
                    "quality": "0",
                    "value": "5.926"
                },
                {
                    "id": "Tag81",
                    "desc": "3#???????????????",
                    "quality": "0",
                    "value": "2.281"
                },
                {
                    "id": "Tag82",
                    "desc": "4#???????????????",
                    "quality": "0",
                    "value": "2.260"
                },
                {
                    "id": "Tag83",
                    "desc": "????????????",
                    "quality": "0",
                    "value": "2.215"
                },
                {
                    "id": "Tag84",
                    "desc": "PH???",
                    "quality": "0",
                    "value": "4.472"
                },
                {
                    "id": "Tag85",
                    "desc": "?????????",
                    "quality": "0",
                    "value": "342.969"
                },
                {
                    "id": "Tag88",
                    "desc": "??????????????????",
                    "quality": "0",
                    "value": "390.864"
                },
                {
                    "id": "Tag87",
                    "desc": "??????????????????",
                    "quality": "0",
                    "value": "4193808.000"
                },
                {
                    "id": "Tag86",
                    "desc": "5#???????????????",
                    "quality": "0",
                    "value": "2.669"
                },
                {
                    "id": "Tag89",
                    "desc": "5#???????????????",
                    "quality": "1",
                    "value": "0.000"
                }
            ]
        },
        "reportTime": 1660701097470,
        "waterworks": "一水厂",
        "position": "控制室",
        "cardNumber": "",
        "remarks": "西门子plc"
      }
      this.loadDetailData()
      this.requestWs()
    }
  },
  destroyed() {
    // 页面销毁时关闭ws
    // closeWebsocket()
  },
};
</script>
<style lang="less" scoped>
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.el-collapse-item {
  box-shadow: 0 0 0;
  border-radius: 0;
  padding: 0;
}
.el-collapse-item /deep/.el-collapse-item__header {
  font-size: 14px;
  color: #333;
}
/deep/.x6-node {
  cursor: pointer;
}
</style>

<style lang="less">
@keyframes animations {//管道动画@keyframes
  to {
    transform: translateX(520px);
  }
}
.hideNode {//隐藏管道动画
    display: none;
}
.showNode {//显示管道动画
    display: block;
}
.pipelineFlow {//管道动画
    animation: animations 3s linear infinite normal;
}


// 外层样式
.container_warp{
  position: relative;
  height: 95.5%;
  .top-operating {
    text-align: center;
    line-height: 20px;
    font-size: 18px;
    width: 20%;
    height: 20px;
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 2;
    background: rgba(255, 255, 255, 0.6);
    padding: 10px;
    border-radius: 10px;
    color: #333;
  }
  .left_drawer {
    height: 100%;
    width: 300px;
    position: absolute;
    left: 0;
    z-index: 1;
    border-right: 1px solid #eee;
    background-color: #FFF;
  }
  #containerDetailChart {
    margin: 0px auto;
    margin-top: 15px;
    background: #6fcbb9df;
  }
  .right_drawer {
    height: 100%;
    width: 300px;
    position: absolute;
    right: 0;
    z-index: 2500;
    border-left: 1px solid #eee;
    background: #fff;
  }
}
.left-search {
  margin: 15px 10px;
}
.btn-group {
  border-right: 1px solid #efefef;
  display: inline-block;
  padding-left: 10px;
  padding-right: 14px;
  position: relative;
  .btn-group_tips {
    text-align: center;
    top: 63px;
    left: 1px;
    position: absolute;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 60px;
    background: #515a6e;
    border-radius:6px;
    animation: tips 4s;
    transition: all 1s;
    &:after {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      top: -5px;
      left: 46%;
      border-width: 0 5px 5px;
      border-style: solid;
      border-color: transparent transparent #515a6e;
    }
  }
  &:last-of-type {
    border-right: 0;
  }
  .btn {
    display: inline-block;
    margin: 2px 6px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    transition: all .4s;
    border-radius: 6px;
    // border: 1px solid rgba(233, 233, 233, 0);
    i {
      font-size: 20px;
    }
  }
  .left-shape {
    margin: 15px 19px;
    span {
      display: inline-block;
      border: 1px solid #000;
    }
  }
  .rect span {
    width: 40px;
    height: 30px;
  }
  .rectry span {
    width: 40px;
    height: 30px;
    border-radius: 10px;
  }
  .square span {
    width: 30px;
    height: 30px;
  }
  .circle span {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .ellipse span {
    width: 40px;
    height: 30px;
    border-radius: 50%;
  }
  .polygon span {
    width: 30px;
    height: 30px;
    transform: rotate(45deg);
  }
}
@keyframes tips {
  0% {opacity: 0;transform:scale(0);}
  10% {opacity: 1;transform:scale(1);}
  90% {opacity: 1;transform:scale(1);}
  100% {opacity: 0;transform:scale(0);}
}
</style>